<template>
    <div class="row">
        <div class="col-12 mb-3">
            <div class="card pt-4 mb-xl-9">
                <div class="card-header border-0">
                    <div class="card-title">
                        <h2 class="fw-bolder">{{ $t("pages.roadmap.availableRoadmaps") }}</h2>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <div class="fw-bolder fs-2">
                        <div class="fs-7 fw-normal text-muted">{{ $t("pages.roadmap.descBis") }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card pt-4 mb-xl-9">
                <div class="card-body">
                    <div class="p-5 mb-5 bg-primary bg-opacity-25 rounded">
                        <div class="row">
                            <div class="col">
                                <input
                                    v-model="filters.identity"
                                    type="text"
                                    name="fname"
                                    class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                    :placeholder="$t('pages.users.name')"
                                    @keyup="
                                        filters.identity.length >= 2
                                            ? debounce(() => {
                                                  getRelatedRoadMaps();
                                              })
                                            : null
                                    "
                                />
                            </div>
                            <div class="col">
                                <input
                                    v-model="filters.perimeter"
                                    type="text"
                                    name="fname"
                                    class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                    :placeholder="$t('pages.usersList.perimeter')"
                                    @keyup="
                                        filters.perimeter.length >= 2
                                            ? debounce(() => {
                                                  getRelatedRoadMaps();
                                              })
                                            : null
                                    "
                                />
                            </div>
                            <div class="col">
                                <button type="submit" class="ms-3 btn btn-icon btn-light btn-active-light-primary" @click="resetFilter()">
                                    <i class="fas fa-redo fs-7 pt-1"></i>
                                </button>
                            </div>
                            <!--end::Col-->
                        </div>
                        <!--end::Row-->
                    </div>
                    <div class="table-responsive" id="printableTable">
                        <table class="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                            <thead>
                                <tr class="fw-bolder text-muted">
                                    <th class="min-w-120px">{{ $t("pages.users.name") }}</th>
                                    <th class="min-w-120px">{{ $t("pages.usersList.perimeter") }}</th>
                                    <th class="min-w-120px text-center">{{ $t("pages.roadmap.titlePlural") }}</th>
                                </tr>
                            </thead>
                            <tbody v-if="!loading">
                                <tr v-for="user in users" v-bind:key="user.id">
                                    <td>
                                        <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">{{ user.userprofile.firstname }}</a>
                                        <span class="text-muted fw-bold text-muted d-block fs-7">{{ user.userprofile.lastname }}</span>
                                    </td>
                                    <td>
                                        <span v-for="company in user.userprofile.related_companies" v-bind:key="company.pk" class="badge-light badge text-primary me-2">
                                            <i class="fas fa-sitemap fs-5 me-2"></i>
                                            {{ company.name }}
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" @click="this.userRoadmap(user.id)">
                                            <span class="svg-icon svg-icon-muted svg-icon-3"
                                                ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                                                    <rect y="6" width="16" height="3" rx="1.5" fill="black" />
                                                    <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="black" />
                                                    <rect opacity="0.3" width="12" height="3" rx="1.5" fill="black" /></svg></span
                                        ></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-if="loading">
                            <div class="d-flex row-auto flex-center w-100 h-200px">
                                <span class="spinner-border text-primary" role="status"></span>
                            </div>
                        </div>
                        <div v-if="noResult">
                            <div class="d-flex row-auto flex-center w-100 h-50px">
                                <span class="text-muted fs-6 fw-bold">{{ $t("general.noResult") }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import roadMapApi from "@/core/services/RoadMap";
import XLSX from "xlsx";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "related-roadmap",
    components: {},
    data: function () {
        return {
            filters: {
                perimeter: "",
                identity: "",
            },
            action: {
                id: 0,
                label: "",
                collaborators: "",
                deadline: "",
                category: 0,
                status: "TODO",
                commentary: "",
                impact: "",
            },
            users: [],
            categories: [],
            loading: true,
            noResult: false,
        };
    },
    methods: {
        /*downloadRoadmap() {
    const data = XLSX.utils.json_to_sheet(this.roadmaps);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, data, "data");
    XLSX.writeFile(wb, "roadmaps.xlsx");
},*/
        getRelatedRoadMaps() {
            this.loading = true;
            this.noResult = false;
            roadMapApi.getRelatedRoadMaps(this.filters).then((response) => {
                if (response.data.users.length === 0) {
                    this.noResult = true;
                } else {
                    this.users = response.data.users;
                    this.noResult = false;
                }
                this.users = response.data.users;
                this.loading = false;
            });
        },
        resetFilter() {
            this.filters = {
                perimeter: "",
                identity: "",
            };
            this.getRelatedRoadMaps();
        },
        deleteAction(action) {
            roadMapApi
                .deleteAction(action.pk)
                .then(() => {
                    location.reload();
                })
                .catch(() => {
                    this.displayErrorMsg("Une erreur est survenue lors de la suppression de l'action.");
                });
        },
        displayErrorMsg(msg: string) {
            Swal.fire({
                text: msg,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Fermer",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                },
            });
        },
    },
    setup() {
        const { t } = useI18n();
        const router = useRouter();

        function createDebounce() {
            let timeout: ReturnType<typeof setTimeout>;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }
        function editRoadmap(this: any, action) {
            this.action.label = action.label;
            this.action.collaborators = action.collaborators;
            this.action.deadline = action.deadline;
            this.action.commentary = action.commentary;
            this.action.category = action.category.pk;
            this.action.status = action.status;
            this.action.id = action.pk;
            this.action.impact = action.impact;

            router.push({
                name: "add-roadmap",
                query: {
                    id: this.action.id,
                    label: this.action.label,
                    theme: this.action.category,
                    collaborators: this.action.collaborators,
                    category: this.action.category,
                    deadline: this.action.deadline,
                    impact: this.action.impact,
                    status: this.action.status,
                    commentary: this.action.commentary,
                },
            });
        }

        function userRoadmap(userId: number) {
            router.push({
                name: "user-roadmaps",
                params: {
                    user_id: userId,
                },
            });
        }

        setCurrentPageBreadcrumbs(t("pages.roadmap.availableRoadmaps"), [
            {
                link: "/roadmap/",
                label: t("pages.roadmap.title"),
            },
        ]);
        return { t, editRoadmap, userRoadmap, debounce: createDebounce() };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.roadmap.title"), [
                {
                    link: "/roadmap/",
                    label: this.t("pages.roadmap.title"),
                },
            ]);
        },
    },
    created() {
        this.getRelatedRoadMaps();
    },
});
</script>
