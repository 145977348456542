
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import roadMapApi from "@/core/services/RoadMap";
import XLSX from "xlsx";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "related-roadmap",
    components: {},
    data: function () {
        return {
            filters: {
                perimeter: "",
                identity: "",
            },
            action: {
                id: 0,
                label: "",
                collaborators: "",
                deadline: "",
                category: 0,
                status: "TODO",
                commentary: "",
                impact: "",
            },
            users: [],
            categories: [],
            loading: true,
            noResult: false,
        };
    },
    methods: {
        /*downloadRoadmap() {
    const data = XLSX.utils.json_to_sheet(this.roadmaps);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, data, "data");
    XLSX.writeFile(wb, "roadmaps.xlsx");
},*/
        getRelatedRoadMaps() {
            this.loading = true;
            this.noResult = false;
            roadMapApi.getRelatedRoadMaps(this.filters).then((response) => {
                if (response.data.users.length === 0) {
                    this.noResult = true;
                } else {
                    this.users = response.data.users;
                    this.noResult = false;
                }
                this.users = response.data.users;
                this.loading = false;
            });
        },
        resetFilter() {
            this.filters = {
                perimeter: "",
                identity: "",
            };
            this.getRelatedRoadMaps();
        },
        deleteAction(action) {
            roadMapApi
                .deleteAction(action.pk)
                .then(() => {
                    location.reload();
                })
                .catch(() => {
                    this.displayErrorMsg("Une erreur est survenue lors de la suppression de l'action.");
                });
        },
        displayErrorMsg(msg: string) {
            Swal.fire({
                text: msg,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Fermer",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                },
            });
        },
    },
    setup() {
        const { t } = useI18n();
        const router = useRouter();

        function createDebounce() {
            let timeout: ReturnType<typeof setTimeout>;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }
        function editRoadmap(this: any, action) {
            this.action.label = action.label;
            this.action.collaborators = action.collaborators;
            this.action.deadline = action.deadline;
            this.action.commentary = action.commentary;
            this.action.category = action.category.pk;
            this.action.status = action.status;
            this.action.id = action.pk;
            this.action.impact = action.impact;

            router.push({
                name: "add-roadmap",
                query: {
                    id: this.action.id,
                    label: this.action.label,
                    theme: this.action.category,
                    collaborators: this.action.collaborators,
                    category: this.action.category,
                    deadline: this.action.deadline,
                    impact: this.action.impact,
                    status: this.action.status,
                    commentary: this.action.commentary,
                },
            });
        }

        function userRoadmap(userId: number) {
            router.push({
                name: "user-roadmaps",
                params: {
                    user_id: userId,
                },
            });
        }

        setCurrentPageBreadcrumbs(t("pages.roadmap.availableRoadmaps"), [
            {
                link: "/roadmap/",
                label: t("pages.roadmap.title"),
            },
        ]);
        return { t, editRoadmap, userRoadmap, debounce: createDebounce() };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.roadmap.title"), [
                {
                    link: "/roadmap/",
                    label: this.t("pages.roadmap.title"),
                },
            ]);
        },
    },
    created() {
        this.getRelatedRoadMaps();
    },
});
